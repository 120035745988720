import React, { Component } from "react";

class Brands extends Component {
    render() {
        return (
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://www.houzz.com/pro/kanstruktgroup/__public" rel="noopener noreferrer" target="_blank"><img src="/assets/images/brand/brand-01.png" alt="Logo Images" /></a>
                    </li>
                    <li>
                        <a href="https://www.houzz.com/pro/kanstruktgroup/__public" rel="noopener noreferrer" target="_blank"><img src="/assets/images/brand/brand-02.png" alt="Logo Images" /></a>
                    </li>
                    <li>
                        <a href="https://www.generalcontractors.org/the-best-general-contractors-in-union-county-new-jersey/" rel="noopener noreferrer" target="_blank"><img src="/assets/images/brand/brand-04.png" alt="Logo Images" /></a>
                    </li>
                    <li>
                        <a href="https://www.generalcontractors.org/the-13-best-general-contractors-in-westfield-new-jersey/" rel="noopener noreferrer" target="_blank"><img src="/assets/images/brand/brand-05.png" alt="Logo Images" /></a>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}

export default Brands;