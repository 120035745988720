/*global google*/

import React, { Component } from "react";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { googleLogo } from '../../utils/config'
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';


class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyDataRetrieved: false,
            error: null,
            rating: null,
            userRatingsTotal: null,
            reviews: []
        }
    }

    componentDidMount() {
        let map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 40.632453, lng: -74.312649 }
        });

        let service = new google.maps.places.PlacesService(map);

        service.getDetails({
            placeId: 'ChIJZRl7hoGxw4kRGxM8E4gdbv0'
        }, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                this.setState({
                    companyDataRetrieved: true,
                    rating: place.rating.toFixed(1),
                    reviews: place.reviews,
                    userRatingsTotal: place.user_ratings_total
                });
            }
        })
    }

    render() {
        const { rating, userRatingsTotal, reviews } = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Box component="fieldset" mb={3} borderColor="transparent">
                            <div className="review-provider">
                                <div className="company-logo">
                                    <img src={googleLogo} alt="Testimonial Images" />
                                </div>
                                <div className="company-rating">
                                    <h3>{rating}</h3>
                                </div>
                                <h3>Kanstrukt Group LLC</h3>
                                <p>based on {userRatingsTotal} reviews</p>
                            </div>
                        </Box>
                        <Tabs>
                            {reviews.map((value, index) => (
                                <TabPanel key={index}>
                                    <div className="text-center">
                                        <Box component="fieldset" mb={3} borderColor="transparent">
                                            <Rating name="read-only" value={value.rating} readOnly />
                                        </Box>
                                        <div className="section-title  inner">
                                            <p>{value.text}</p>
                                        </div>
                                        <div className="review_author">
                                            <h6><span>{value.author_name}</span> ({value.relative_time_description})
                                            </h6>
                                        </div>
                                    </div>
                                </TabPanel>
                            ))}
                            <TabList className="testimonial-thumb-wrapper">
                                {reviews.map((value, index) => (
                                    <Tab key={index}>
                                        <div className="testimonial-thumbnai">
                                            <div className="thumb">
                                                <img src={`${value.profile_photo_url}`} alt="Testimonial Images" />
                                            </div>
                                        </div>
                                    </Tab>
                                ))}
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Testimonial;