const imagesBaseURL = 'assets/images/galleries/'
const googlePlacesAPIKey = 'AIzaSyDagCegvI3SpYdR7DavACIIG5SArrOGfAU'
const googlePlaceID = 'ChIJZRl7hoGxw4kRGxM8E4gdbv0'
const googleCompanyData = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${googlePlaceID}&key=${googlePlacesAPIKey}`

const payPalQRCode = `${imagesBaseURL}qr_code/qrcode.png`
const googleLogo = `${imagesBaseURL}logos/google-logo.png`

function importAll(r) {
    return r.keys().map(r);
}

const imagesCarousel = importAll(require.context('../../public/assets/images/carousel/', false, /\.(JPE?G|PNG|png|jpe?g|svg)$/));
const imagesCustomHomes = importAll(require.context('../../public/assets/images/galleries/imagesCustomHomes/', false, /\.(JPE?G|PNG|png|jpe?g|svg)$/));
const imagesAdditions = importAll(require.context('../../public/assets/images/galleries/imagesAdditions/', false, /\.(JPE?G|PNG|png|jpe?g|svg)$/));
const imagesKitchens = importAll(require.context('../../public/assets/images/galleries/imagesKitchens/', false, /\.(JPE?G|PNG|png|jpe?g|svg)$/));
const imagesBathrooms = importAll(require.context('../../public/assets/images/galleries/imagesBathrooms/', false, /\.(JPE?G|PNG|png|jpe?g|svg)$/));
const imagesFinishWork = importAll(require.context('../../public/assets/images/galleries/imagesFinishWork/', false, /\.(JPE?G|PNG|png|jpe?g|svg)$/));
const imagesCommercialProjects = importAll(require.context('../../public/assets/images/galleries/imagesCommercialProjects/', false, /\.(JPE?G|PNG|png|jpe?g|svg)$/));

const ourServices = [
    {
        image: '01',
        title: 'Commercial Projects',
        gallery: 'imagesCommercialProjects',
        description: 'Fast and reliable office & retail space renovation, demolition, excavation and foundation services.',
    },
    {
        image: '02',
        title: 'Custom Homes',
        gallery: 'imagesCustomHomes',
        description: 'We are your partner. Let us manage all phases of construction from start to finish.',
    },
    {
        image: '03',
        title: 'Additions',
        gallery: 'imagesAdditions',
        description: 'When you need more space. Add more room and value to your home.',
    },
    {
        image: '04',
        title: 'Kitchens',
        gallery: 'imagesKitchens',
        description: 'We believe that the kitchen is a vital epicenter of your home.',
    },
    {
        image: '05',
        title: 'Bathrooms',
        gallery: 'imagesBathrooms',
        description: 'Transform your bathroom into a sanctuary with a better layout and luxurious finishes.',
    },
    {
        image: '06',
        title: 'Finish Work',
        gallery: 'imagesFinishWork',
        description: 'No detail is too small. Our thoughtful and precise approach delivers flawless results.',
    }
]

module.exports = {
    payPalQRCode: payPalQRCode,
    googleLogo: googleLogo,
    googlePlacesAPIKey: googlePlacesAPIKey,
    googleCompanyData: googleCompanyData,
    imagesCarousel: imagesCarousel,
    imagesCustomHomes: imagesCustomHomes,
    imagesAdditions: imagesAdditions,
    imagesKitchens: imagesKitchens,
    imagesBathrooms: imagesBathrooms,
    imagesFinishWork: imagesFinishWork,
    imagesCommercialProjects: imagesCommercialProjects,
    ourServices: ourServices
}
