import React, { Component, Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';

import {
    imagesAdditions,
    imagesBathrooms,
    imagesFinishWork,
    imagesCustomHomes,
    imagesKitchens,
    imagesCommercialProjects,
    ourServices
} from "../utils/config"

import Brands from "../elements/brands/Brands";
import HeaderCarousel from "../elements/common/HeaderCarousel";
import Contact from "../elements/contact/Contact";
import Footer from "../elements/footer/Footer";
import Helmet from "../elements/common/Helmet";
import Testimonial from "../elements/testimonial/Testimonial";
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { FiChevronUp, FiMenu, FiX } from "react-icons/fi";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


class KanstruktHome extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        this.state = {
            photoIndex: 0,
            isOpen: false,
        };

        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })

    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() {
    }

    render() {
        const { photoIndex, isOpen } = this.state;

        const galleries = {
            imagesCustomHomes,
            imagesAdditions,
            imagesKitchens,
            imagesBathrooms,
            imagesFinishWork,
            imagesCommercialProjects
        };

        function getGallery(name) {
            return galleries[name];
        }

        const elements = document.querySelectorAll('.has-droupdown > a');
        for (let i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return (
            <Fragment>
                <Helmet pageTitle="Home" />

                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color sticky">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-main" src="/assets/images/logo/logo.png" alt="Company Logo" />
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu"
                                    items={['home', 'service', 'about', 'testimonial']}
                                    currentClassName="is-current" offset={-200}>
                                    <li><a href="#home" onClick={this.CLoseMenuTrigger}>Home</a></li>
                                    <li><a href="#service" onClick={this.CLoseMenuTrigger}>Services</a></li>
                                    <li><a href="#about" onClick={this.CLoseMenuTrigger}>About</a></li>
                                    <li><a href="#testimonial" onClick={this.CLoseMenuTrigger}>Testimonials</a></li>
                                    <li><a href="#contact" onClick={this.CLoseMenuTrigger}><button className="rn-button-style--2 btn-solid" type="button">Get Started</button></a></li>
                                </Scrollspy>
                            </nav>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div id="home">
                    <HeaderCarousel />
                </div>
                {/* End Slider Area   */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" id="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="section-title text-left mb--30">
                                    <h2>Our Services</h2>
                                    <p>Whether it's a small remodel, a building addition, or a custom home built from the foundations up, Kanstrukt Group views every project as an opportunity to make your dreams a reality. Applying best-practice methodologies, the latest technology, and our unique Agile Construction approach reduces risk and client stress, ensuring we are 100% in alignment with your expectations throughout the development of the project.</p>
                                </div>
                            </div>
                        </div>
                        {/* Start Single Service  */}
                        <div className="col-lg-12">
                            <div className="row">
                                {ourServices.map((value, index) => (
                                    <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                        <div className="standard-service">
                                            <div className="thumbnail">
                                                <img src={`/assets/images/featured/featured-${value.image}.jpeg`}
                                                    alt="Featured Images" />
                                            </div>
                                            <div className="content">
                                                <h3>{value.title}</h3>
                                                <p>{value.description}</p>
                                                <a className="btn-transparent rn-btn-dark"
                                                    href="/#service" onClick={() => this.setState({
                                                        isOpen: true,
                                                        currentGallery: getGallery(value.gallery)
                                                    })}><span
                                                        className="text">Gallery</span></a>
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={this.state.currentGallery[photoIndex]}
                                                        nextSrc={this.state.currentGallery[(photoIndex + 1) % this.state.currentGallery.length]}
                                                        prevSrc={this.state.currentGallery[(photoIndex + this.state.currentGallery.length - 1) % this.state.currentGallery.length]}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}
                                                        onMovePrevRequest={() =>
                                                            this.setState({
                                                                photoIndex: (photoIndex + this.state.currentGallery.length - 1) % this.state.currentGallery.length,
                                                            })
                                                        }
                                                        onMoveNextRequest={() =>
                                                            this.setState({
                                                                photoIndex: (photoIndex + 1) % this.state.currentGallery.length,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* End Single Service  */}
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--1" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-us.jpeg"
                                            alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title text-left mb--30">
                                            <h2>Our Team</h2>
                                            <p>We enjoy partnering with our clients to bring structure to their dreams, and it starts with bringing together the right resources, expertise, and leadership to successfully deliver each unique project.</p>
                                            <p>Based in Clark, New Jersey, we have over a decade of experience managing small to large building projects throughout the New York & New Jersey area. Each member of the team has been hand picked for their experience, integrity, and solution-oriented mindset. Everyone is committed to surpassing client expectations, and we take pride in each positive customer testimonial.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120" id="testimonial">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="section-title text-left mb--30">
                                    <h2>What our clients say</h2>
                                </div>
                            </div>
                        </div>
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact />
                </div>
                {/* End Contact Us */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Brands />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default KanstruktHome;