import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'
import { googlePlacesAPIKey } from '../../utils/config'
import * as emailjs from "emailjs-com";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Alert from "@material-ui/lab/Alert";

const LocationPin = ({ text }) => (
    <div className="pin">
        <Icon icon={locationIcon} height={25} className="pin-icon" />
        <p className="pin-text">{text}</p>
    </div>
)

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 40.63,
            lng: -74.31
        },
        zoom: 11
    };

    constructor(props) {
        super(props);
        this.state = {
            contactSubmitButton: "",
            contactSuccessAlert: "contact_success_hidden",
            contactErrorAlert: "contact_error_hidden"
        };
    }

    updateStateSuccess = () => {
        this.setState({
            contactSubmitButton: "contact_submit_hidden",
            contactSuccessAlert: "",
            contactErrorAlert: "contact_error_hidden"
        }, () => {
        })
    }

    updateStateError = () => {
        this.setState({
            contactSubmitButton: "contact_submit_hidden",
            contactSuccessAlert: "contact_success_hidden",
            contactErrorAlert: ""
        }, () => {
        })
    }

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Schedule a Complimentary Consultation</h2>
                                <p>Kanstrukt Group LLC</p>
                                <p>Clark, New Jersey, United States</p>
                                <a href="https://newjersey.mylicense.com/verification/Search.aspx?facility=Y"
                                   target="_blank" rel="noopener noreferrer"> Lic# 13VH10250800</a><br/>
                                <a href="tel:908-670-2300"><b>(908) 670-2300</b></a><br/>
                                <a href="mailto:info@kanstruktgroup.com">info@kanstruktgroup.com</a><br/>
                            </div>
                            <div className="form-wrapper">
                                <Formik
                                    initialValues={{
                                        email: '',
                                        licensed_insured: [],
                                        own_crew: [],
                                        use_subcontractors: [],
                                        subcontractors_license: [],
                                        materials_labor_separate: [],
                                        written_warranty: [],
                                        bankruptcy: [],
                                        daily_cleaning: [],
                                        jobsite_visit: []
                                    }}
                                    validate={values => {
                                        const errors = {};

                                        if (!values.email) {
                                            errors.email = 'Required';
                                        } else if (
                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                        ) {
                                            errors.email = 'Invalid email address';
                                        }

                                        if (!values.name) {
                                            errors.name = 'Required';
                                        }

                                        if (!values.phone) {
                                            errors.phone = 'Required';
                                        } else if (
                                            !/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/g.test(values.phone)
                                        ) {
                                            errors.phone = 'Invalid phone number';
                                        }

                                        return errors;
                                    }}
                                    onSubmit={(values, actions) => {
                                        window.gtag_report_conversion();
                                        actions.setSubmitting(true)
                                        setTimeout(() => {
                                            emailjs.send("service_outbound", "template_o1vjh1s", // Email template ID provided by EmailJS
                                                {
                                                    from_name: values.name,
                                                    to_name: "Steve Pietraszek",
                                                    reply_to: values.email,
                                                    message_phone: values.phone,
                                                    message_project: values.project
                                                },
                                                "user_aIZw43H6s8Ctc7QA4uLrc"
                                            ).then(() => {
                                                actions.setSubmitting(false)
                                                actions.resetForm()
                                                this.updateStateSuccess()
                                            }).catch(() => {
                                                actions.setSubmitting(false)
                                                this.updateStateError()
                                            })
                                        }, 1000);
                                    }}
                                >
                                    {({isSubmitting}) => (
                                        <Form>
                                            <h6 className="application-sections">Name, Contact Details and Project
                                                Details</h6>
                                            <Field className="formik-field" type="text" name="name"
                                                   placeholder="Your Name"/>
                                            <ErrorMessage className="formik-error" name="name" component="div"/>
                                            <Field className="formik-field" type="text" name="email"
                                                   placeholder="Your Email"/>
                                            <ErrorMessage className="formik-error" name="email" component="div"/>
                                            <Field className="formik-field" type="text" name="phone"
                                                   placeholder="Your Phone"/>
                                            <ErrorMessage className="formik-error" name="phone" component="div"/>
                                            <Field className="formik-field" type="text" name="project"
                                                   placeholder="Brief description of your project"/>
                                            <ErrorMessage className="formik-error" name="project" component="div"/>

                                            <button id={this.state.contactSubmitButton}
                                                    className="rn-button-style--3 btn-solid" type="submit"
                                                    disabled={isSubmitting}>
                                                Submit
                                            </button>
                                            <div>
                                                <Alert id={this.state.contactErrorAlert} variant="filled"
                                                       severity="error">
                                                    There was an error submitting your estimate request.
                                                </Alert>
                                                <Alert id={this.state.contactSuccessAlert} variant="filled"
                                                       severity="success">
                                                    Your estimate request was submitted successfully.
                                                </Alert>
                                            </div>

                                        </Form>
                                    )}
                                </Formik></div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-2">
                            <div className="rn-contact-map-area position-relative">
                                <div style={{height: '650px', width: '100%', paddingTop: '2.5em'}}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: `${googlePlacesAPIKey}`,
                                            language: 'en'
                                        }}
                                        defaultCenter={this.props.center}
                                        defaultZoom={this.props.zoom}
                                    >
                                        <LocationPin
                                            lat={40.632453}
                                            lng={-74.312649}
                                            text="Kanstrukt Group"
                                        />
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;