import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { imagesCarousel } from "../../utils/config"

function HeaderCarousel() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={imagesCarousel[0]}
          alt="First slide"
        />
        <Carousel.Caption>
          <h2>Kanstrukt. Can do.</h2>
          <h3>Award-winning residential and <br />commercial construction</h3>
          <a href="#contact"><button className="rn-button-style--4 btn-solid" type="button">FREE CONSULTATION</button></a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={imagesCarousel[1]}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h2>Kanstrukt. Can do.</h2>
          <h3>Award-winning residential and <br />commercial construction</h3>
          <a href="#contact"><button className="rn-button-style--4 btn-solid" type="button">FREE CONSULTATION</button></a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={imagesCarousel[2]}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h2>Kanstrukt. Can do.</h2>
          <h3>Award-winning residential and <br />commercial construction</h3>
          <a href="#contact"><button className="rn-button-style--4 btn-solid" type="button">FREE CONSULTATION</button></a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={imagesCarousel[3]}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h2>Kanstrukt. Can do.</h2>
          <h3>Award-winning residential and <br />commercial construction</h3>
          <a href="#contact"><button className="rn-button-style--4 btn-solid" type="button">FREE CONSULTATION</button></a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={imagesCarousel[4]}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h2>Kanstrukt. Can do.</h2>
          <h3>Award-winning residential and <br />commercial construction</h3>
          <a href="#contact"><button className="rn-button-style--4 btn-solid" type="button">FREE CONSULTATION</button></a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HeaderCarousel;