import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import KanstruktHome from './home/KanstruktHome';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import * as serviceWorker from './utils/serviceWorker';

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={KanstruktHome} />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();